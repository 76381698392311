import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Material
import Typography from "@material-ui/core/Typography";
// Controls
import Btn from "./../../Controls/Button/Button";
// Custom Hooks
import usePriceSummary from "./../../../CustomHooks/usePriceSummary";
import useProductPrice from "./../../../CustomHooks/useProductPrice";
import useProduct from "./../../../CustomHooks/useProduct";
import useCheckoutForm from "../../../CustomHooks/useCheckoutForm";
// Styles
import useStyles from "./CartPopover.style.js";
import { CURRENCY } from "../../../utils/Constants";
import { useState } from "react";
import { useEffect } from "react";
import { setNoOrderType } from "../../../store/Restaurant/RestaurantActions.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

export default function CartPopover() {
  const classes = useStyles();
  const state = useSelector((state) => state.cart);
  const { hotelKey, hotel, tableId } = useSelector((state) => state.hotel);
  const priceSummary = usePriceSummary();
  const { getOriginalPrice, getItemTotalPrice } = useProduct();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const { formValues } = useCheckoutForm();
  const { nextAvailableTime } = useSelector((state) => state.time);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!tableId) {
      // if (
      //   formValues.orderType === null ||
      //   (hotel?.openingAvailable === false && hotel?.is_preorder === 0) ||
      //   nextAvailableTime === null
      // ) {
      //   setIsBtnDisabled(true);
      // }
      if (
        formValues?.orderType === "DELIVERY" &&
        hotel?.deliveryAvailable === false &&
        hotel?.is_preorder === 0
      ) {
        setIsBtnDisabled(true);
      }
      if (
        formValues?.orderType === "PICKUP" &&
        hotel?.pickupAvailable === false &&
        hotel?.is_preorder === 0
      ) {
        setIsBtnDisabled(true);
      }
    }
  }, [formValues.orderType, hotel, tableId]);
  // console.log("priceSummary.subTotal", priceSummary.subTotal);

  const handleBtnClick = () => {
    if (
      formValues.orderType === null ||
      (hotel?.openingAvailable === false && hotel?.is_preorder === 0) ||
      nextAvailableTime === null
    ) {
      dispatch(setNoOrderType(true));
      return;
    }
    history.push(`/${hotelKey}/cart`);
    // dispatch(setNoOrderType(false));
  };

  return (
    <div className={classes.container}>
      <div className={classes.OderListContainer}>
        {state.data.map((c, i) => (
          <CartPopoverItem key={i} cartItem={c} />
        ))}
        <div className={classes.totalContainer}>
          <Typography className={classes.text}>Total</Typography>
          <div className={classes.grow} />
          <Typography className={classes.text}>
            {CURRENCY.SIGN}
            {getOriginalPrice(priceSummary.total)}
          </Typography>
        </div>
        {/* <Link to={`/${hotelKey}/cart`}> */}
        <Btn
          disabled={isBtnDisabled}
          text={"Go to Cart"}
          className={classes.btn}
          handleClick={handleBtnClick}
        />
        {/* </Link> */}
      </div>
    </div>
  );
}

function CartPopoverItem({ cartItem }) {
  const classes = useStyles();
  const { total } = useProductPrice(cartItem._cartItemId);
  const { getOriginalPrice, getItemTotsalPrice } = useProduct();
  return (
    <div className={classes.OderListItemContainer}>
      <Typography className={classes.text}>
        {cartItem.label || cartItem.combo_name || ""}
      </Typography>
      <Typography className={classes.countText}> x {cartItem.count}</Typography>
      <div className={classes.grow} />
      <Typography className={classes.text}>
        {CURRENCY.SIGN}
        {cartItem?.freeCount > 0
          ? getItemTotsalPrice(cartItem)
          : getOriginalPrice(total)}
      </Typography>
    </div>
  );
}
