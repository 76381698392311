import React, { useCallback, useEffect, useState } from "react";
import { Formik, Field } from "formik";
// Controls
import InputField from "../../Controls/InputField/InputField";
// ValidationSchemas
import LoginDetailsFormSchema from "./LoginDetailsForm.schema";
import LoginDetailsLoginFormSchema from "./LoginDetailsLoginForm.schema";

import { formatGoogleAddress } from "../../../utils/Utility";
import { useSelector, useDispatch } from "react-redux";
// import { getDistanceDuration } from "../../../store/Location/LocationActions";
import {
  Box,
  CircularProgress,
  Dialog,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import useStyles from "./LoginDetailsForm.style";
import Autocomplete from "react-google-autocomplete";
import { VerifiedUser } from "@material-ui/icons";
import Btn from "../../Controls/Button/Button";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { firebaseAuth, setUpRecaptcha } from "../../../firebase";
import OtpInput from "react-otp-input";
import clsx from "clsx";
import {
  createUser,
  emailLoginRequest,
  emailOtpRequest,
  loginUser,
} from "../../../store/User/UserAction";
import { signInWithPhoneNumber } from "firebase/auth";
import { dispatchSnackbarError } from "../../../utils/Shared";
import { ADDRESS_TYPE_HOME, ORDER_TYPES } from "../../../utils/Constants";
import { appMapsCountry } from "../../../config/app-config";
import { numberPrefix } from "../../../config/app-config";
import {
  getFirestore,
  doc,
  setDoc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { app } from "../../../firebase";
import SpecialAlert from "../../SpecialAlert/SpecialAlert";
import SocialMediaLogin from "../../SocialMediaLogin/SocialMediaLogin";
import {
  setDirectLogin,
  triggerEmailVerification,
  verifyEmailOtp,
} from "../../../store/Profile/ProfileActions";
import BasicLoader from "../../Loader/BasicLoader";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import CheckoutLoader from "../../CheckoutLoader/CheckoutLoader";

const firestore = getFirestore(app);

let locObj = null;
const initialState = {
  customer_uid: "",
  customer_name: "",
  customer_phone: "",
  customer_email: "",
  customer_address: "",
  customer_city: "",
  customer_zipcode: "",
  customer_geoLoc: null,
};

const LoginDialogCon = ({ setOpen, directLogin = false }) => {
  // const [formType, setFormType] = useState("signup"); // login signup otp

  const [formType, setFormType] = useState("login"); // login signup otp
  const user = useSelector((state) => state.user);
  const [otpLoading, setOtpLoading] = useState(false);
  const [isOtpLimitAlert, setIsOtpLimitAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setOpen(false);
    }
  }, [user, directLogin]);

  // console.log("formType", formType);

  const [loginFormValues, setLoginFormValues] = useState({ ...initialState });
  const changeFormType = (type) => {
    setFormType(type);
    if (!type === "signup") {
      setLoginFormValues({ ...initialState });
    }
  };
  // const sendOTP = () => {
  //   if (window?.recaptchaVerifier) {
  //     const appVerifier = window.recaptchaVerifier;
  //     signInWithPhoneNumber(
  //       firebaseAuth,
  //       // `${phoneNumberPrefix}${loginFormValues.customer_phone}`,
  //       `${numberPrefix}${loginFormValues.customer_phone}`,
  //       appVerifier
  //     )
  //       .then((confirmationResult) => {
  //         window.confirmationResult = confirmationResult;
  //         setOtpLoading(false);
  //         setFormType("otp");
  //       })
  //       .catch((error) => {
  //         dispatchSnackbarError(error.toString());
  //         setOpen(false);
  //       });
  //   }
  // };

  const sendOTP = async () => {
    if (window?.recaptchaVerifier) {
      const appVerifier = window.recaptchaVerifier;
      const otpCountRef = doc(firestore, "otpCount", "dailyCount"); // Reference to the OTP count document

      // Get the current date in a format like "YYYY-MM-DD"
      const currentDate = new Date().toISOString().split("T")[0];
      // console.log("current data", currentDate);

      try {
        const docSnapshot = await getDoc(otpCountRef);

        if (docSnapshot.exists) {
          const { date, count } = docSnapshot.data();
          // console.log("date from firebase", date);
          // console.log("count", count);

          if (date === currentDate && count >= 10) {
            // If the date is today and the count has reached 10, show an alert
            // alert("You have reached the OTP sending limit for today.");
            setIsOtpLimitAlert(true);
            setOtpLoading(false);
          } else {
            // If the date is today, attempt to send OTP
            const confirmationResult = await signInWithPhoneNumber(
              firebaseAuth,
              // `${numberPrefix}${loginFormValues.customer_phone}`,
              `${loginFormValues.customer_phone}`,

              appVerifier
            );

            window.confirmationResult = confirmationResult;
            setOtpLoading(false);
            setFormType("otp");

            if (date === currentDate) {
              // If the date is today, increment the count
              await updateDoc(otpCountRef, {
                count: count + 1,
              });
            } else {
              // If the date is not today, set a new count and update the date
              await setDoc(otpCountRef, {
                date: currentDate,
                count: 1,
              });
            }
          }
        } else {
          // If the document doesn't exist, create it with the current date and a count of 1
          await setDoc(otpCountRef, {
            date: currentDate,
            count: 1,
          });
        }
      } catch (error) {
        console.error("Error sending OTP or checking OTP count:", error);
      }
    }
  };

  return (
    <div style={{ width: "100%", maxWidth: "400px" }}>
      {loading ? <CheckoutLoader /> : null}
      {formType === "login" ? (
        <LoginForm
          changeFormType={changeFormType}
          setOpen={setOpen}
          loginFormValues={loginFormValues}
          setLoginFormValues={setLoginFormValues}
          sendOTP={sendOTP}
          otpLoading={otpLoading}
          setOtpLoading={setOtpLoading}
          setLoading={setLoading}
          directLogin={directLogin}
        />
      ) : // <SocialMediaLogin
      //   setOpen={setOpen}
      //   changeFormType={changeFormType}
      //   setLoginFormValues={setLoginFormValues}
      //   loginFormValues={loginFormValues}
      //   directLogin={directLogin}
      // />
      formType === "signup" ? (
        <SignUpForm
          changeFormType={changeFormType}
          setOpen={setOpen}
          loginFormValues={loginFormValues}
          setLoginFormValues={setLoginFormValues}
          sendOTP={sendOTP}
          otpLoading={otpLoading}
          setOtpLoading={setOtpLoading}
          loading={loading}
        />
      ) : formType === "otp" ? (
        <OTPForm
          changeFormType={changeFormType}
          setOpen={setOpen}
          loginFormValues={loginFormValues}
          setLoginFormValues={setLoginFormValues}
          setLoading={setLoading}
          loading={loading}
        />
      ) : null}
      <SpecialAlert
        open={isOtpLimitAlert}
        handleClose={() => {
          setIsOtpLimitAlert(false);
          setOpen(false);
        }}
        title={"Login limit exceeded please use open checkout"}
        // image={hotel?.alert_image}
      />
    </div>
  );
};
export default LoginDialogCon;

//////////////////////////////////////signup form
export const SignUpForm = ({
  setOpen,
  changeFormType,
  loginFormValues,
  setLoginFormValues,
  sendOTP,
  otpLoading,
  setOtpLoading,
  loading,
}) => {
  const classes = useStyles();
  const [tempMail, setTempMail] = useState("");
  const dispatch = useDispatch();
  const { hotelKey } = useSelector((state) => state.hotel);
  const history = useHistory();
  const { isLocationProcessed } = useSelector((state) => state.location);
  const { checkoutDetails } = useSelector((state) => state.cart);
  const {
    isTriggerringEmailIsInProcess,
    isEmailVerified,
    triggeredEmailResponse,
    isDirectLogin,
  } = useSelector((state) => state.profile);
  const [otp, setOtp] = useState(null);
  const [isLoginFieldError, setIsLoginFieldError] = useState(false);
  const user = useSelector((state) => state.user);
  // console.log("login detailssss", loginFormValues);
  // console.log("loginFormValues", loginFormValues);

  const signup = () => {
    try {
      // if (
      //   !(checkoutDetails.orderType === ORDER_TYPES.PICKUP) &&
      //   !loginFormValues.customer_address
      // ) {
      //   setIsLoginFieldError(true);
      // } else {
      const data = {
        uid: loginFormValues?.customer_uid,
        name: loginFormValues?.customer_name,
        // phone_no: `${numberPrefix}${loginFormValues?.customer_phone}`,
        phone_no: `${loginFormValues?.customer_phone}`,
        email: loginFormValues?.customer_email,
        // address: [
        //   {
        //     address: loginFormValues?.customer_address,
        //     city: loginFormValues?.customer_city,
        //     zipcode: loginFormValues?.customer_zipcode,
        //     geoLoc: loginFormValues?.customer_geoLoc,
        //     type: ADDRESS_TYPE_HOME,
        //   },
        // ],
      };
      dispatch(createUser(data));
      setOpen(false);
      setLoginFormValues({ ...initialState });
      // }
    } catch (error) {
      dispatchSnackbarError(error?.toString() || "Something wend wrong!");
      setOpen(false);
    }
  };

  useEffect(() => {
    if (isEmailVerified) {
      onChangeLoginForm("customer_email", tempMail);
    }
  }, [isEmailVerified]);

  const fieldChange = (e) => {
    if (
      e.target.id === "customer_email" &&
      loginFormValues?.customer_email === ""
    ) {
      setTempMail(e.target.value);
      return;
    }
    onChangeLoginForm(e.target.id, e.target.value);
  };

  const onChangeLoginForm = (fieldName, value) => {
    setLoginFormValues((prev) => ({ ...prev, [fieldName]: value }));
  };

  const onLocationSelect = (loc) => {
    const formattedAddress = formatGoogleAddress(loc);
    locObj = formattedAddress;
    const geoLoc = {
      latitude: loc?.geometry?.location?.lat(),
      longitude: loc?.geometry?.location?.lng(),
    };
    onChangeLoginForm("customer_geoLoc", geoLoc);
    // console.log("loccccccc", loc);
    // do not call calculate distance on signup
    if (locObj) {
      setFormValues({ ...locObj });
      locObj = null;
    }
    // dispatch(getDistanceDuration(geoLoc));
  };

  const setFormValues = useCallback(
    (googleAdd) => {
      setIsLoginFieldError(false);
      if (googleAdd.address !== loginFormValues.customer_address) {
        onChangeLoginForm("customer_address", googleAdd.address);
      }
      if (googleAdd.city !== loginFormValues.customer_city) {
        onChangeLoginForm("customer_city", googleAdd.city);
      }
      if (googleAdd.zipcode !== loginFormValues.customer_zipcode) {
        onChangeLoginForm("customer_zipcode", googleAdd.zipcode);
      }
    },
    [
      loginFormValues.customer_address,
      loginFormValues.customer_city,
      loginFormValues.customer_zipcode,
    ]
  );

  useEffect(() => {
    if (isLocationProcessed && locObj) {
      setFormValues({ ...locObj });
      locObj = null;
    }
  }, [isLocationProcessed, dispatch, setFormValues]);

  const handleTriggerEmail = () => {
    const data = {
      uid: loginFormValues?.customer_name_uid,
      email: tempMail,
    };
    dispatch(triggerEmailVerification(data));
  };

  const handleOTPChange = (e) => {
    const inputOtp = e;
    if (inputOtp.match(/^[0-9]*$/)) {
      setOtp(inputOtp);
    }
  };

  const handleVerifyOTP = () => {
    if (otp.length === 6) {
      const data = {
        uid: loginFormValues?.customer_name_uid,
        email: tempMail,
        code: otp,
      };
      dispatch(verifyEmailOtp(data));
      setOtp(null);
    }
  };

  return (
    <>
      {isTriggerringEmailIsInProcess && !isEmailVerified && <BasicLoader />}
      <DialogTitle id="alert-dialog-title">SIGN UP</DialogTitle>
      <DialogContent>
        <div id="checkout-contactForm">
          <Formik
            initialValues={loginFormValues}
            validationSchema={LoginDetailsFormSchema}
            onSubmit={(values) => {
              signup();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form
                id="signup-form"
                onSubmit={handleSubmit}
                onChange={handleChange}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px 0",
                }}
              >
                <Field
                  id="customer_name"
                  name="customer_name"
                  type="text"
                  placeholder="Full name"
                  value={values.customer_name}
                  component={InputField}
                  isSignupFormm={true}
                  handleBlur={handleBlur}
                  handleChange={fieldChange}
                  isError={
                    touched.customer_name && errors.customer_name ? true : false
                  }
                  helperText={
                    touched.customer_name && errors.customer_name
                      ? errors.customer_name
                      : null
                  }
                />
                <Field
                  id="customer_phone"
                  name="customer_phone"
                  type="tel"
                  isSignupFormm={true}
                  placeholder="Mobile number"
                  value={values.customer_phone}
                  component={InputField}
                  handleBlur={handleBlur}
                  handleChange={fieldChange}
                  // startAdornment={
                  //   <InputAdornment position="start">
                  //     {/* <Typography className={classes.prefixStyle}>
                  //       {numberPrefix}
                  //     </Typography> */}
                  //   </InputAdornment>
                  // }
                  isError={
                    touched.customer_phone && errors.customer_phone
                      ? true
                      : false
                  }
                  helperText={
                    touched.customer_phone && errors.customer_phone
                      ? errors.customer_phone
                      : null
                  }
                />
                <Field
                  classes={{
                    input: classes.input,
                  }}
                  id="customer_email"
                  name="customer_email"
                  type="email"
                  placeholder="Email"
                  isSignupFormm={true}
                  value={values.customer_email}
                  component={InputField}
                  handleBlur={handleBlur}
                  handleChange={fieldChange}
                  disabled={loginFormValues?.customer_email || isEmailVerified}
                  readOnly={loginFormValues?.customer_email || isEmailVerified}
                  isError={
                    touched.customer_email && errors.customer_email
                      ? true
                      : false
                  }
                  helperText={
                    touched.customer_email && errors.customer_email
                      ? errors.customer_email
                      : null
                  }
                  endAdornment={
                    isEmailVerified && (
                      <InputAdornment position="end">
                        <VerifiedUser className={classes.verifiedText} />
                      </InputAdornment>
                    )
                  }

                  // InputProps={{
                  //   endAdornment: isEmailVerified ? (
                  //     <span className={classes.verifiedText}>Verified</span>
                  //   ) : null,
                  // }}
                />
                {/* {isEmailVerified ? (
                  <span className={classes.verifiedText}>Verified</span>
                ) : null} */}
                {!loginFormValues?.customer_email && !isEmailVerified && (
                  <div className={classes.veryfiyBtnDiv}>
                    <Button
                      onClick={handleTriggerEmail}
                      className={classes.verifyBtn}
                    >
                      Verify Email
                    </Button>
                  </div>
                )}
                {triggeredEmailResponse && !isEmailVerified && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <OtpInput
                      value={otp}
                      onChange={handleOTPChange}
                      numInputs={6}
                      separator={<span>-</span>}
                      inputStyle={classes.otpInputBox}
                      className={classes.otpInput}
                      // containerStyle={{
                      //   width: "100%",
                      //   display: "flex",
                      //   alignItems: "center",
                      //   justifyContent: "space-between",
                      // }}
                      shouldAutoFocus
                      isInputNum
                    />
                    <div
                      className={classes.veryfiyBtnDiv}
                      style={{ marginTop: "20px" }}
                    >
                      <Button
                        onClick={handleVerifyOTP}
                        color="primary"
                        variant="contained"
                        className={classes.verifyBtn}
                      >
                        Verify OTP
                      </Button>
                    </div>
                  </div>
                )}
                <div id="recaptcha-container" />
              </form>
            )}
          </Formik>
          {/* {!loginFormValues.customer_address && (
            <OutlinedInput
              fullWidth
              error={isLoginFieldError}
              classes={{
                input: classes.input,
              }}
              inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
                <Autocomplete
                  {...props}
                  options={{
                    types: ["address"],
                    componentRestrictions: { country: appMapsCountry },
                  }}
                  use
                  onPlaceSelected={(loc) => onLocationSelect(loc)}
                />
              )}
            />
          )} */}
          {/* {isLoginFieldError && (
            <span
              style={{
                color: "#9C0A35",
                fontSize: "0.75rem",
                marginTop: "8px",
              }}
            >
              Required
            </span>
          )} */}
          {loginFormValues.customer_address && (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <OutlinedInput
                fullWidth
                classes={{
                  input: classes.input,
                }}
                value={loginFormValues.customer_address}
                disabled={true}
              />
              <Btn
                handleClick={() => {
                  setLoginFormValues({
                    ...loginFormValues,
                    customer_address: "",
                    customer_city: "",
                    customer_zipcode: "",
                  });
                }}
                text="Clear"
                className={classes.loginBtn}
              />
            </div>
          )}
          {/* <div className={classes.textCon}>
            Already have an account?
            <span
              className={classes.text}
              onClick={() => changeFormType("login")}
            >
              LOGIN
            </span>
          </div> */}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Cancel
        </Button>

        <Button
          type="submit"
          form="signup-form"
          color="primary"
          disabled={
            loading || (!loginFormValues?.customer_email && !isEmailVerified)
          }
        >
          {loading ? "SIGING UP..." : "SIGNUP"}
        </Button>
      </DialogActions>
    </>
  );
};
////////////////////////////////////////Login form
const LoginForm = ({
  setOpen,
  changeFormType,
  loginFormValues,
  setLoginFormValues,
  sendOTP,
  otpLoading,
  setOtpLoading,
  setLoading,
  directLogin,
}) => {
  const history = useHistory();
  const { hotelKey } = useSelector((state) => state.hotel);

  const fieldChange = (e) => {
    onChangeLoginForm(e.target.id, e.target.value);
  };
  const onChangeLoginForm = (fieldName, value) => {
    setLoginFormValues((prev) => ({ ...prev, [fieldName]: value }));
  };

  const prefixStyle = {
    color: "#000",
  };

  const dispatch = useDispatch();
  // const userCountry = useSelector(state => state.location.userCountry);
  const userCountryCode = useSelector(
    (state) => state.location.userCountryCode
  );

  // useEffect(() => {
  //   fetch('https://api.ipify.org?format=json')
  //     .then(response => response.json())
  //     .then(data => {
  //       setIP(data.ip);
  //       fetch(`https://ipapi.co/${data.ip}/country/`)
  //         .then(response => response.text())
  //         .then(data => setCountry(data));
  //     });
  // }, []);

  // console.log("userrr countryyyyy", userCountryCode?.data)
  // useEffect(() => {
  //   async function getLocation() {
  //     dispatch(getUserCountryRequest())
  //   }
  //   getLocation()
  //   if (userCountryCode) {
  //     updateNumberPrefix(userCountryCode?.data)
  //   }
  // }, [dispatch, userCountryCode])

  // useEffect(() => {
  //   async function getLocation() {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         position => {
  //           const userLocation = {
  //             lat: position.coords.latitude,
  //             lon: position.coords.longitude
  //           };
  //           dispatch(getLocationData(userLocation));
  //         },
  //         error => {
  //           console.log(error);
  //         }
  //       );
  //     } else {
  //     }
  //   };
  //   getLocation()
  //   updateNumberPrefix(userCountry)
  // }, [userCountry]);

  const onLogin = (value) => {
    // console.log("working login func", value);
    try {
      const payload = {
        email: loginFormValues?.customer_email,
        onSuccess: () => {
          changeFormType("otp"); // Change form type to "otp" on success
        },
        setLoading: setLoading,
      };
      dispatch(emailLoginRequest(payload));
      setOtpLoading(true);
      // setUpRecaptcha();
      // sendOTP();
    } catch (error) {
      dispatchSnackbarError(error?.toString() || "Something went wrong!");
      setOpen(false);
    }
  };
  // console.log("valuesssssssss", loginFormValues);

  const handleCancel = () => {
    let sanitizedHotelKey = hotelKey.replace(/\/?profile\/?/, ""); // Removes "profile" with or without slashes

    if (directLogin) {
      dispatch(setDirectLogin(false));
      history.push(`/${sanitizedHotelKey}`);
      setOpen(false);
      setOtpLoading(false);
      setLoading(false);
    } else {
      setOpen(false);
    }
  };

  return (
    // <>
    //   <DialogTitle id="alert-dialog-title">LOGIN</DialogTitle>
    //   <DialogContent>
    //     <div id="checkout-contactForm">
    //       <Formik
    //         initialValues={loginFormValues}
    //         validationSchema={LoginDetailsLoginFormSchema}
    //         onSubmit={(values) => {
    //           onLogin(values);
    //         }}
    //       >
    //         {({
    //           values,
    //           errors,
    //           touched,
    //           handleChange,
    //           handleBlur,
    //           handleSubmit,
    //         }) => (
    //           <form
    //             id="signup-form"
    //             onSubmit={handleSubmit}
    //             onChange={handleChange}
    //             style={{
    //               width: "100%",
    //               display: "flex",
    //               flexDirection: "column",
    //               gap: "24px 0",
    //             }}
    //           >
    //             {/* <Field
    //               id="customer_phone"
    //               name="customer_phone"
    //               type="tel"
    //               placeholder="Mobile number"
    //               value={values.customer_phone}
    //               component={InputField}
    //               handleBlur={handleBlur}
    //               handleChange={fieldChange}
    //               // startAdornment={
    //               //   <InputAdornment position="start">
    //               //     <Typography style={prefixStyle}>
    //               //       {numberPrefix}
    //               //     </Typography>
    //               //   </InputAdornment>
    //               // }
    //               isError={
    //                 touched.customer_phone && errors.customer_phone
    //                   ? true
    //                   : false
    //               }
    //               helperText={
    //                 touched.customer_phone && errors.customer_phone
    //                   ? errors.customer_phone
    //                   : null
    //               }
    //             /> */}
    //             <Field
    //               id="customer_email"
    //               name="customer_email"
    //               type="email"
    //               placeholder="Email"
    //               value={values.customer_email}
    //               component={InputField}
    //               handleBlur={handleBlur}
    //               handleChange={fieldChange}
    //               // startAdornment={
    //               //   <InputAdornment position="start">
    //               //     <Typography style={prefixStyle}>
    //               //       {numberPrefix}
    //               //     </Typography>
    //               //   </InputAdornment>
    //               // }
    //               isError={
    //                 touched.customer_email && errors.customer_email
    //                   ? true
    //                   : false
    //               }
    //               helperText={
    //                 touched.customer_email && errors.customer_email
    //                   ? errors.customer_email
    //                   : null
    //               }
    //             />
    //             <div id="recaptcha-container" />
    //           </form>
    //         )}
    //       </Formik>
    //       {/* <div className={classes.textCon}>
    //         Don't have an account?
    //         <span
    //           className={classes.text}
    //           onClick={() => changeFormType("signup")}
    //         >
    //           SIGNUP
    //         </span>
    //       </div> */}
    //     </div>
    //   </DialogContent>
    //   <DialogActions>
    //     <Button onClick={handleCancel} color="primary">
    //       Cancel
    //     </Button>
    //     <Button
    //       type="submit"
    //       form="signup-form"
    //       color="primary"
    //       disabled={otpLoading}
    //       onClick={onLogin}
    //     >
    //       {otpLoading ? "REQUESTING..." : "REQUEST OTP"}
    //     </Button>
    //   </DialogActions>
    // </>
    <Dialog open onClose={handleCancel} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{ textAlign: "center", fontWeight: "bold", fontSize: "1.5rem" }}
      >
        LOGIN
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Formik
            initialValues={loginFormValues}
            validationSchema={LoginDetailsLoginFormSchema}
            onSubmit={(values) => {
              onLogin(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form
                id="signup-form"
                onSubmit={handleSubmit}
                onChange={handleChange}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Field
                  as={TextField}
                  id="customer_email"
                  name="customer_email"
                  label="Email"
                  type="email"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    onChangeLoginForm("customer_email", e.target.value)
                  }
                  onBlur={handleBlur}
                  error={
                    touched.customer_email && Boolean(errors.customer_email)
                  }
                  helperText={touched.customer_email && errors.customer_email}
                />
                <Box id="recaptcha-container" sx={{ alignSelf: "center" }} />
              </form>
            )}
          </Formik>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", padding: "16px" }}>
        <Button onClick={handleCancel} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          type="submit"
          form="signup-form"
          variant="contained"
          color="primary"
          disabled={otpLoading}
          onClick={onLogin}
        >
          {otpLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Request OTP"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

////////////////////////////////////////OTP Form
const OTPForm = ({
  setOpen,
  loginFormValues,
  setLoginFormValues,
  changeFormType,
  setLoading,
  loading,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const user = useSelector((state) => state.user);
  const [otp, setOtp] = useState("");
  const [verifyLoader, setVerifyLoader] = useState(false);

  const handleOTPChange = (num) => {
    setOtp(num);
    confirmOTPHandler(num);
  };
  const confirmOTPHandler = (num) => {
    if (num.toString().length === 6) {
      setVerifyLoader(true);
      // console.log("nummmmmmmm", num);
      const payload = {
        email: loginFormValues?.customer_email, // Customer's email from form
        otp: num, // OTP entered by the user
        onSuccess: () => {
          setVerifyLoader(false); // Stop loader once the OTP is successfully verified
          // Close the dialog or perform any other action on success
          setOpen(false);
        },
        changeFormType: (type) => {
          changeFormType(type); // Dynamically change the form type
          setVerifyLoader(false); // Stop loader once the form type changes
        },
        setLoading: setLoading,
        setCustomerUid: (uid) => {
          // console.log("uiddddddd", uid);
          setLoginFormValues({ ...loginFormValues, customer_uid: uid }); // Set customer UID in form
        },
      };
      dispatch(emailOtpRequest(payload));
      // verifyOTP(num);
    }
  };

  const verifyOTP = (otpNum) => {
    try {
      const confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otpNum)
        .then((result) => {
          // User signed in successfully.
          const FBUser = result?.user;
          setLoginFormValues({ ...loginFormValues, customer_uid: FBUser?.uid });
          setVerifyLoader(false);
          dispatch(
            loginUser({
              uid: FBUser?.uid,
              cbs: () => {
                changeFormType("signup");
              },
              cbl: () => {
                changeFormType("login");
                setOpen(false);
              },
            })
          );
        })
        .catch((error) => {
          dispatchSnackbarError(error.toString());
          // setOpen(false);
          setVerifyLoader(false);
        });
    } catch (error) {
      dispatchSnackbarError("Something went wrong!");
      // setOpen(false);
      setVerifyLoader(false);
    }
  };
  // console.log("otpppppppppp", otp);

  return (
    <Dialog open maxWidth="xs" fullWidth>
      {/* <DialogTitle
        sx={{ textAlign: "center", fontWeight: "bold", fontSize: "1.5rem" }}
      >
        ENTER OTP
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            className={classes.textCon}
            style={{ marginTop: 0, marginBottom: "16px" }}
          >
            Please type the OTP sent to {`${loginFormValues?.customer_email}`}
          </Typography>

         
          <input
            type="text"
            value={otp}
            onChange={(e) => handleOTPChange(e.target.value)}
            maxLength={6}
            className={clsx(classes.otpInputBoxDiv)}
            placeholder="Enter OTP"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            changeFormType("signup");
          }}
          color="primary"
        >
          Cancel
        </Button>

        {verifyLoader && (
          <Button
            type="submit"
            color="primary"
            disabled={otp?.toString()?.length !== 6 || verifyLoader}
            onClick={() => confirmOTPHandler(otp)}
          >
            {loading ? "VERIFYING..." : "VERIFY"}
          </Button>
        )}
      </DialogActions> */}
      <DialogTitle
        id="alert-dialog-title"
        sx={{ textAlign: "center", fontWeight: 600 }}
      >
        ENTER OTP
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              color: "text.secondary",
              marginBottom: "10px !important",
            }}
          >
            Please type the OTP sent to{" "}
            <strong>{loginFormValues?.customer_email}</strong>
          </Typography>
          <TextField
            fullWidth
            type="text"
            value={otp}
            onChange={(e) => handleOTPChange(e.target.value)}
            variant="outlined"
            placeholder="Enter OTP"
            inputProps={{
              maxLength: 6,
              style: {
                textAlign: "center",
                fontSize: "18px",
                height: "30px",
                padding: "5px",
              },
            }}
            sx={{
              width: "100%",
              maxWidth: "250px",
              marginTop: "10px",
              "& .MuiOutlinedInput-root": {
                height: "40px",
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", pb: 2 }}>
        <Button
          onClick={() => {
            setOpen(false);
            changeFormType("signup");
          }}
          variant="outlined"
          color="error"
        >
          Cancel
        </Button>
        {/* {verifyLoader && ( */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={otp?.toString()?.length !== 6 || verifyLoader}
          onClick={() => confirmOTPHandler(otp)}
          sx={{ minWidth: 120 }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "VERIFY"}
        </Button>
        {/* )} */}
      </DialogActions>
    </Dialog>
  );
};
