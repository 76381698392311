import { makeStyles } from "@material-ui/core/styles";
import {
  calculateFontSize,
  setFontColorOnBg,
} from "../../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    background: theme?.palette?.common?.background,
    color: theme?.palette?.common?.themeFontColor,
    boxShadow: 24,
    padding: "20px",
    borderRadius: "10px",
  },
  saveBtn: {
    fontWeight: "bold",
    backgroundColor: theme?.palette?.button?.buttonColor || "black",
    color: setFontColorOnBg(theme?.palette?.button?.buttonColor || "#000000"),
    borderRadius: theme?.palette?.button?.borderRadius || "12px",
    border: theme?.palette?.button?.buttonBorder ? "1px solid black" : null,
    "&:hover": {
      backgroundColor: "#000",
      color: "#fff",
      cursor: "pointer",
    },
  },
}));

export default useStyles;
