import { Divider, makeStyles, Typography } from "@material-ui/core";
import { CURRENCY } from "../../../utils/Constants";
import Btn from "../../Controls/Button/Button";
import {
  calculateFontSize,
  setFontColorOnBg,
} from "../../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  modalTitle: {
    fontSize: calculateFontSize(20, theme.palette.common.fontVariation),
    fontWeight: 700,
  },
  modalSubTitle: {
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    fontWeight: 700,
    paddingTop: "16px",
    color: theme.palette.common.themeFontColor,
  },
  button: {
    marginTop: "30px",
    backgroundColor: theme?.palette?.button?.buttonColor || "white",
    color: setFontColorOnBg(theme?.palette?.button?.buttonColor || "#ffffff"),
  },
}));

const CouponSuccess = ({
  discount,
  handleClose = () => {},
  directCheckOut,
  proceedToCheckout = () => {},
  isCustomTitle = false,
}) => {
  const classes = useStyles();
  // console.log("modal workinggggggggggg", directCheckOut);
  return (
    <div className="p-4 text-center">
      <Typography
        className={`text-success pb-2 ${classes.modalTitle}`}
        variant="h1"
        align="center"
        paragraph
      >
        {"Congratulations"}
      </Typography>
      <Divider />
      {isCustomTitle ? (
        <Typography
          className={classes.modalSubTitle}
          variant="h3"
          align="center"
          paragraph
        >
          {discount}
        </Typography>
      ) : (
        <Typography
          className={classes.modalSubTitle}
          variant="h3"
          align="center"
          paragraph
        >
          Great coupon successfully applied , you saved {CURRENCY.SIGN}
          {discount} on this order
        </Typography>
      )}
      <Btn
        text="Ok"
        className={classes.button}
        handleClick={() =>
          directCheckOut ? proceedToCheckout() : handleClose()
        }
      />
    </div>
  );
};

export default CouponSuccess;
