import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// Custom hook
import usePrevious from "./usePrevious";
import useCalculateDistance from "./useCalculateDistance";
// Utils
import { COUPONS, ORDER_TYPES, PAYMENT_METHODS } from "../utils/Constants";
import useCheckoutForm from "./useCheckoutForm";
import useProductPrice from "./useProductPrice";
import useWalletPoints from "./useWalletPoints";
import {
  setDiscountType,
  setFreeDeliveryAmountApplied,
  updateCart,
  walletModal,
} from "../store/Cart/CartActions";
import { setUsedWalletPoint } from "../store/Profile/ProfileActions";

const usePriceSummary = () => {
  const state = useSelector((state) => state.cart);
  const { hotel, userData } = useSelector((state) => state.hotel);
  const { voucherData } = useSelector((state) => state.voucher);
  const [products, setProducts] = useState([]);
  const { redumptionRatio, usedWalletPoints } = useSelector(
    (state) => state.profile
  );
  const dispatch = useDispatch();
  const {
    filterCartProductsLoyality,
    PointsToRedeemIfMax,
    getWalletPointsFromProductPrice,
    getPriceWithAvailableWalletPoints,
    getWalletPointsFromCartPrice,
    calculateTotalPrice,
  } = useWalletPoints();
  // const [total, setTotal] = useState(0);
  // const [subTotal, setSubTotal] = useState(0);
  // const [deliveryCharge, setDeliveryCharge] = useState(0);
  // const [serviceCharge, setServiceCharge] = useState(0);
  // const [tax, setTax] = useState(0);
  // const [discount, setDiscount] = useState(0);

  const previousProducts = usePrevious(products);
  const { deliveryCharge } = useCalculateDistance();
  const { formValues } = useCheckoutForm();
  let _mPrice = 0;

  useEffect(() => {
    let total = 0;
    if (state?.data) {
      state?.data?.forEach((item) => {
        if (item?.walletUsedForProduct) {
          total += item?.walletUsedForProduct;
        }
      });
      dispatch(setUsedWalletPoint(total));
    }
  }, [state?.data, dispatch]);

  /**
   * useMemo returns total of all products
   */
  const getWalletRedeemedSubTotal = useMemo(() => {
    let _subTotal = 0;
    products.forEach((p) => {
      let _t = 0;
      if (p?.isWalletRedeemed && p?.walletType == 2) {
        const size =
          p.sizes?.length > 0 && p.size
            ? p.sizes.find((elem) => elem.id === Number(p.size))
            : null;
        if (size) {
          _t += p?.walletAmount
            ? Number(size.size_amount) - parseInt(p?.walletAmount)
            : Number(size.size_amount);
        } else {
          _t += p?.walletAmount
            ? Number(p?.sale_price) - parseInt(p?.walletAmount)
            : Number(p.sale_price);
        }
      } else {
        const size =
          p.sizes?.length > 0 && p.size
            ? p.sizes.find((elem) => elem.id === Number(p.size))
            : null;
        if (p.sale_price >= 0) {
          _t += size ? Number(size.size_amount) : Number(p.sale_price);
        }
        if (p.combo_price) {
          _t += Number(p.combo_price);
        }
      }
      if (p.count) {
        _t = _t * Number(p.count);
      }
      // console.log("pppppp", p);
      if (!p?.combo_id && p.modifiers?.length) {
        p.modifiers.forEach(
          (m) => (_t += Number(m.price) * Number(m.count) * Number(p.count))
        );
      }
      if (p.combos) {
        if (
          p.combos.comboItems &&
          Object.keys(p.combos.comboItems).length > 0
        ) {
          for (let key of Object.keys(p.combos.comboItems)) {
            // p.combos.comboItems[key] && (_t += p.combos.comboItems[key].sale_price);
            p.combos.comboItems[key].modifiers &&
              p.combos.comboItems[key].modifiers.forEach(
                (m) =>
                  (_t += Number(m.price) * Number(m.count) * Number(p.count))
              );
            // _t += _mPrice;
            // setComboModifierPrice(comboModifierPrice + _mPrice);
          }
        }
        if (
          p.combos.comboCatItems &&
          Object.keys(p.combos.comboCatItems).length > 0
        ) {
          for (let key of Object.keys(p.combos.comboCatItems)) {
            if (
              p.combos.comboCatItems[key] &&
              Object.keys(p.combos.comboCatItems[key]).length > 0
            ) {
              for (let c of Object.keys(p.combos.comboCatItems[key])) {
                _t +=
                  p.combos.comboCatItems[key][c].extra_price *
                  p.combos.comboCatItems[key][c].count;
              }

              for (let item of Object.keys(p.combos.comboCatItems[key])) {
                // p.combos.comboCatItems[key][item] && (_t += p.combos.comboCatItems[key][item].sale_price);

                p.combos.comboCatItems[key][item].modifiers &&
                  p.combos.comboCatItems[key][item].modifiers.forEach(
                    (m) =>
                      (_t +=
                        Number(m.price) * Number(m.count) * Number(p.count))
                    //combo modifier price
                    // Number(m.price) *
                    // Number(m.count) *
                    // Number(p.count) *
                    // Number(p.combos.comboCatItems[key][item].count))
                  );
                // _t += _mPrice;
                // setComboModifierPrice(comboModifierPrice + _mPrice);
              }
            }
          }
        }
      }
      _subTotal += _t;
    });
    return _subTotal;
  });

  const getProductTotal = useMemo(() => {
    let _subTotal = 0;
    products.forEach((p) => {
      let _t = 0;
      // console.log("sale priceeee", p.sale_price)
      // if (p?.isWalletRedeemed && p?.walletType == 2) {
      //   const size =
      //     p.sizes?.length > 0 && p.size
      //       ? p.sizes.find((elem) => elem.id === Number(p.size))
      //       : null;
      //   if (size) {
      //     _t += p?.walletAmount
      //       ? Number(size.size_amount) - parseInt(p?.walletAmount)
      //       : Number(size.size_amount);
      //   } else {
      //     _t += p?.walletAmount
      //       ? Number(p?.sale_price) - parseInt(p?.walletAmount)
      //       : Number(p.sale_price);
      //   }
      // } else {
      const size =
        p.sizes?.length > 0 && p.size
          ? p.sizes.find((elem) => elem.id === Number(p.size))
          : null;
      if (p.sale_price >= 0) {
        _t += size ? Number(size.size_amount) : Number(p.sale_price);
      }
      if (p.combo_price) {
        _t += Number(p.combo_price);
      }
      // }
      if (p.count) {
        _t = _t * Number(p.count);
      }
      // console.log("pppppp", p);
      if (!p?.combo_id && p.modifiers?.length) {
        p.modifiers.forEach(
          (m) => (_t += Number(m.price) * Number(m.count) * Number(p.count))
        );
      }
      if (p.combos) {
        if (
          p.combos.comboItems &&
          Object.keys(p.combos.comboItems).length > 0
        ) {
          for (let key of Object.keys(p.combos.comboItems)) {
            // p.combos.comboItems[key] && (_t += p.combos.comboItems[key].sale_price);
            p.combos.comboItems[key].modifiers &&
              p.combos.comboItems[key].modifiers.forEach(
                (m) =>
                  (_t += Number(m.price) * Number(m.count) * Number(p.count))
              );
            // _t += _mPrice;
            // setComboModifierPrice(comboModifierPrice + _mPrice);
          }
        }
        if (
          p.combos.comboCatItems &&
          Object.keys(p.combos.comboCatItems).length > 0
        ) {
          for (let key of Object.keys(p.combos.comboCatItems)) {
            if (
              p.combos.comboCatItems[key] &&
              Object.keys(p.combos.comboCatItems[key]).length > 0
            ) {
              for (let c of Object.keys(p.combos.comboCatItems[key])) {
                _t +=
                  p.combos.comboCatItems[key][c].extra_price *
                  p.combos.comboCatItems[key][c].count;
              }

              for (let item of Object.keys(p.combos.comboCatItems[key])) {
                // p.combos.comboCatItems[key][item] && (_t += p.combos.comboCatItems[key][item].sale_price);

                p.combos.comboCatItems[key][item].modifiers &&
                  p.combos.comboCatItems[key][item].modifiers.forEach(
                    (m) =>
                      (_t +=
                        Number(m.price) * Number(m.count) * Number(p.count))
                    //combo modifier price
                    // Number(m.price) *
                    // Number(m.count) *
                    // Number(p.count) *
                    // Number(p.combos.comboCatItems[key][item].count))
                  );
                // _t += _mPrice;
                // setComboModifierPrice(comboModifierPrice + _mPrice);
              }
            }
          }
        }
      }
      _subTotal += _t;
    });
    return _subTotal;
  }, [products, previousProducts]);

  /**
   * useMemo returns tax amount based on all products total
   */
  const getTax = useMemo(() => {
    let productTotal = Number(getProductTotal);
    // console.log("productTotal::::::: ", hotel?.tax_exclusive, hotel?.tax, productTotal, productTotal*3, (productTotal*3)/23)
    let calculatedTax = 0;

    if (hotel?.tax_exclusive === 0) {
      calculatedTax = (productTotal * 3) / 23;
      // console.log("tax amount", calculatedTax)
    } else {
      calculatedTax = productTotal * ((hotel?.tax || 1) / 100);
    }
    // console.log("calculatedTax:::::::::::", calculatedTax);

    return calculatedTax;
  }, [hotel, getProductTotal]);

  // const getTax = useMemo(() => {
  //   let productTotal = Number(getProductTotal);
  //   let calculatedTax = 0;

  //   if (hotel?.tax_exclusive === 0) {
  //     console.log("hotel", hotel)
  //     calculatedTax = productTotal * (50 / 100)
  //     console.log("tax amount", calculatedTax)
  //   } else {
  //     calculatedTax = productTotal * (50 / 100)
  //   }
  //   console.log("calculated tax", calculatedTax)
  //   return calculatedTax
  // }, [hotel, getProductTotal])

  /**
   * useMemo returns subtotal (with/without tax) based on product total and tax
   */

  const getSubtotal = useMemo(() => {
    let _subTotal = getProductTotal;
    // _subTotal = hotel?.tax_exclusive === 1 ? _subTotal - getTax : _subTotal;
    return _subTotal;
  }, [hotel, getProductTotal, getTax]);

  /**
   * useMemo returns surCharge based on orderType
   */
  const getDeliveryCharge = useMemo(() => {
    // console.log("stateeeeeeeeeeeee", state);
    let _deliveryCharge = 0;

    if (state.checkoutDetails.orderType === ORDER_TYPES.DELIVERY) {
      _deliveryCharge = deliveryCharge;
    }

    // if (
    //   state?.isFreeDeliveryCouponApplied &&
    //   state?.selectedCoupon?.rules?.type === "FREE_DELIVERY"
    // ) {
    //   dispatch(setFreeDeliveryAmountApplied(true));
    //   if (
    //     _deliveryCharge >= state?.selectedCoupon?.result?.MAX_DISCOUNT_VALUE
    //   ) {
    //     _deliveryCharge -= state?.selectedCoupon?.result?.MAX_DISCOUNT_VALUE;
    //   } else {
    //     _deliveryCharge = 0;
    //   }
    // }

    return _deliveryCharge;
  }, [
    deliveryCharge,
    state.checkoutDetails.orderType,
    state?.selectedCoupon,
    state?.isFreeDeliveryCouponApplied,
  ]);

  // const getDeliveryCharge = useMemo(() => {
  //   console.log("stateeeeeeeeeeeee", state);
  //   let _deliveryCharge = 0;
  //   if (state.checkoutDetails.orderType === ORDER_TYPES.DELIVERY) {
  //     _deliveryCharge = deliveryCharge;
  //   }

  //   return _deliveryCharge;
  // }, [deliveryCharge, state.checkoutDetails.orderType,state?.selectedCoupon,state?.isFreeDeliveryCouponApplied]);

  /**
   * useMemo returns discount
   */
  const calculateCartDiscount = ({
    subTotal,
    discountPercent,
    selectedCoupon,
  }) => {
    let discountAmt = (subTotal * discountPercent) / 100 || 0;
    if (discountAmt > Number(selectedCoupon?.result?.MAX_DISCOUNT_VALUE)) {
      discountAmt = Number(selectedCoupon?.result?.MAX_DISCOUNT_VALUE);
    }
    return discountAmt;
  };

  const calculateItemDiscount = ({
    subTotal,
    discountPercent,
    selectedCoupon,
    cartItems,
  }) => {
    const applicableItems = selectedCoupon?.items || [];
    if (!applicableItems.length || !cartItems || !cartItems.length) {
      return 0;
    }

    let eligibleItems;

    if (Number(discountPercent) === 100) {
      const items = cartItems.filter((item) =>
        applicableItems.includes(item.itemId)
      );
      const duplicateRemoved = items.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.itemId === value.itemId)
      );
      const closest =
        duplicateRemoved?.length > 0
          ? duplicateRemoved?.reduce((acc, loc) =>
              acc?.sale_price < loc?.sale_price ? acc : loc
            )
          : null;
      eligibleItems = closest ? [closest] : [];
    } else {
      eligibleItems = cartItems.filter((item) =>
        applicableItems.includes(item.itemId)
      );
    }

    let discount = 0;
    eligibleItems.forEach((item) => {
      let itemCountPrice = 0;
      //if discount=100% then only give only 1 item free
      if (item?.size && item?.size != "" && item?.sizes && item?.sizes != []) {
        const matchedSize = item?.sizes.find(
          (s) => s.id === parseInt(item?.size)
        ); // Assuming size is a string and id is a number
        if (matchedSize) {
          const size_amount = matchedSize.size_amount;
          if (Number(discountPercent) === 100) {
            if (selectedCoupon?.result?.MAX_DISCOUNT_VALUE < size_amount) {
              itemCountPrice = selectedCoupon?.result?.MAX_DISCOUNT_VALUE;
            } else {
              itemCountPrice = size_amount;
            }
          } else {
            itemCountPrice = size_amount * item.count;
          }
        }
      } else {
        if (Number(discountPercent) === 100) {
          if (selectedCoupon?.result?.MAX_DISCOUNT_VALUE < item.sale_price) {
            itemCountPrice = selectedCoupon?.result?.MAX_DISCOUNT_VALUE;
          } else {
            itemCountPrice = item.sale_price;
          }
        } else {
          itemCountPrice = item.sale_price * item.count;
        }
      }
      const itemDiscount = (itemCountPrice * discountPercent) / 100 || 0;
      discount += itemDiscount;
    });
    return discount;
  };

  const calculateDeliveryCouponDiscount = (deliCharge, dis) => {
    // console.log("delivery chargeeeeeeeee", deliCharge);
    // console.log("disssssssssssss", dis);
    let discount = 0;

    // Apply the discount only if it does not exceed the delivery charge
    if (dis <= deliCharge) {
      discount = dis;
    } else {
      discount = deliCharge;
    }

    // console.log("discount in funcccccc", discount);
    return discount;
  };

  const calculateWalletDiscount = (total) => {
    const amountToReduce = getPriceWithAvailableWalletPoints(
      PointsToRedeemIfMax(usedWalletPoints)
    );
    return amountToReduce;
  };

  const getActualPrice = (item) => {
    let price = item?.sale_price;

    if (item?.size) {
      const sizeItem = item?.sizes?.find((it) => it.id == item?.size);
      if (sizeItem) {
        price = sizeItem.size_amount;
      }
    }

    return price;
  };

  const getDiscount = useMemo(() => {
    let _discount = 0;
    // let _discountPercent = 0;
    const { selectedCoupon, priceSummary, data, discountType } = state;
    if (selectedCoupon?.id && priceSummary?.subTotal) {
      const discountPercent =
        selectedCoupon?.result?.type === COUPONS.FREE_DELIVERY
          ? Number(selectedCoupon?.result?.MAX_DISCOUNT_VALUE) || 0
          : Number(selectedCoupon?.result?.DISCOUNT) || 0;
      // dispatch(setDiscountType("coupon"));
      const subTotal =
        Number(priceSummary?.subTotal) + Number(priceSummary?.deliveryCharge);
      // Number(priceSummary?.subTotal) + Number(priceSummary?.deliveryCharge);

      if (discountPercent) {
        if (selectedCoupon?.result?.type === COUPONS.CART_DISCOUNT) {
          _discount = calculateCartDiscount({
            subTotal,
            discountPercent,
            selectedCoupon,
          });
          // _discount = discountAmt * 100 / subTotal;
        }

        if (selectedCoupon?.result?.type === COUPONS.ITEM_DISCOUNT) {
          _discount = calculateItemDiscount({
            subTotal,
            discountPercent,
            selectedCoupon,
            cartItems: data,
          });
        }
        // console.log("deliveryCharge", deliveryCharge);
        if (
          selectedCoupon?.result?.type === COUPONS.FREE_DELIVERY &&
          deliveryCharge
        ) {
          _discount = calculateDeliveryCouponDiscount(
            deliveryCharge,
            discountPercent
          );
        }
      }
      if (selectedCoupon?.result?.type === COUPONS.BUY_ONE_GET_ONE_FREE) {
        const applicableItems =
          selectedCoupon?.rules?.APPLICABLE_ITEMS?.map((item) => item.id) || [];
        let bogofDiscount = 0;

        data?.forEach((cartItem) => {
          if (cartItem?.isAutoAdded) {
            const salePrice = getActualPrice(cartItem);
            let freeCount = cartItem.count; // Free count is equal to bought count
            // const maxFreeCount = selectedCoupon?.result?.max_free_count || 0;

            // Ensure freeCount does not exceed max_free_count
            // freeCount = Math.min(freeCount, maxFreeCount);

            // Calculate discount as (free items * sale price)
            bogofDiscount += freeCount * salePrice;
          }
        });

        _discount += bogofDiscount;
      }
    } else if (usedWalletPoints && priceSummary?.subTotal) {
      _discount = getPriceWithAvailableWalletPoints(usedWalletPoints);
      // const filteredProds = filterCartProductsLoyality(data);
      // const total = calculateTotalPrice(filteredProds);

      // const amountToReduce = getPriceWithAvailableWalletPoints(
      //   PointsToRedeemIfMax(usedWalletPoints)
      // );
      // _discount = amountToReduce;

      // filteredProds?.forEach((prod) => {
      //   let productPrice = prod.sale_price;

      //   if (prod?.size && prod?.sizes?.length > 0) {
      //     const sizeDetail = prod?.sizes.find((size) => size?.id == prod?.size);
      //     if (sizeDetail) {
      //       productPrice = sizeDetail.size_amount;
      //     }
      //   }

      //   const prodPercentage = ((productPrice / total) * 100).toFixed(2);
      //   const contributionAmount = (
      //     (amountToReduce * prodPercentage) /
      //     100
      //   ).toFixed(2);

      //   const percentageOfDiscount = (
      //     (contributionAmount / productPrice) *
      //     100
      //   ).toFixed(2);

      // dispatch(
      //   updateCart({
      //     ...prod,
      //     isWalletRedeemed: true,
      //     walletDiscount: percentageOfDiscount,
      //     walletAmount: contributionAmount,
      //     walletType: 1,
      //     walletUsedForProduct:
      //       getWalletPointsFromProductPrice(contributionAmount),
      //   })
      // );
      // dispatch(setDiscountType("wallet"));
      // return _discount;
      // });
    }
    // console.log("_discount", _discount);
    return _discount;
  }, [
    products,
    state?.priceSummary,
    state?.selectedCoupon,
    usedWalletPoints,
    deliveryCharge,
  ]);

  // console.log("stateeeeeeeeeee", state?.data);
  /**
   * useMemo returns overall total with surcharge
   */

  const getTotalWithOutSurcharge = useMemo(() => {
    let _tot = 0;

    if (hotel) {
      _tot = getSubtotal + getDeliveryCharge - getDiscount;

      // _tot = hotel?.tax_exclusive === 1 ? _tot + getTax : _tot;
    }
    return _tot;
  }, [hotel, getSubtotal, getDeliveryCharge, getDiscount]);

  /**
   * useMemo returns surCharge based on Total
   */
  const getSurCharge = useMemo(() => {
    let calculatedSurCharge = 0;
    // console.log("hotel?.surcharge_amount", hotel?.surcharge_amount);
    // console.log("hotel?.surcharge_percent", hotel?.surcharge_percent);
    const hotelSurChargeAmount = Number(hotel?.surcharge_amount) || 0;
    const hotelSurChargePer = Number(hotel?.surcharge_percent) || 0;
    if (
      hotel?.surcharge_enable &&
      formValues.paymentMethod === PAYMENT_METHODS.ONLINE_PAYMENT
    ) {
      calculatedSurCharge += hotelSurChargeAmount;
      calculatedSurCharge +=
        getTotalWithOutSurcharge * (hotelSurChargePer / 100);
    }
    // console.log("calculatedSurCharge", calculatedSurCharge);
    return calculatedSurCharge;
  }, [hotel, formValues.paymentMethod, getTotalWithOutSurcharge]);

  // const getUsedWalletPointPrice = useMemo(() => {
  //   if (usedWalletPoints != null && redumptionRatio != null) {
  //     return usedWalletPoints / redumptionRatio?.ratio_points;
  //   } else {
  //     return 0;
  //   }
  // }, [usedWalletPoints, redumptionRatio]);

  const getTotal = useMemo(() => {
    // console.log("getsurchargeeeeeee", getSurCharge);
    // console.log("getTotalWithOutSurcharge", getTotalWithOutSurcharge);
    return getTotalWithOutSurcharge + getSurCharge;
  }, [getTotalWithOutSurcharge, getSurCharge]);

  const getPayableAmount = useMemo(() => {
    // return getTotalWithOutSurcharge + getSurCharge - getUsedWalletPointPrice;
    return getTotalWithOutSurcharge + getSurCharge;
  }, [getTotalWithOutSurcharge, getSurCharge]);

  const getVoucherDiscount = useMemo(() => {
    let voucherDiscount = 0;
    let remainingValue = 0;
    let totalAfterAppliedVoucher = 0;

    if (voucherData?.value) {
      const voucherValue = Number(voucherData.value);
      const originalSubTotal = Number(getTotal);

      if (voucherValue >= originalSubTotal) {
        let val = voucherValue - originalSubTotal;
        remainingValue = val;
        voucherDiscount = originalSubTotal;
        totalAfterAppliedVoucher = 0.0;
      } else {
        let val = originalSubTotal - voucherValue;
        totalAfterAppliedVoucher = val;
        voucherDiscount = voucherValue;
        remainingValue = 0;
      }
    }

    return {
      voucherDiscount: voucherDiscount.toFixed(2),
      remainingValue: remainingValue.toFixed(2),
      totalAfterAppliedVoucher: totalAfterAppliedVoucher.toFixed(2),
    };
  }, [voucherData, getTotal]);

  useEffect(() => {
    setProducts(state.data);
  }, [state]);

  return {
    subTotal: getSubtotal.toFixed(2),
    deliveryCharge: getDeliveryCharge.toFixed(2),
    discount: getDiscount.toFixed(2),
    tax: getTax.toFixed(2),
    serviceCharge: getSurCharge.toFixed(2),
    total: getPayableAmount.toFixed(2),
    subTotalWithoutDiscount: getSubtotal.toFixed(2),
    // walletPoints: getUsedWalletPointPrice.toFixed(2),
    // voucherDiscount: getVoucherDiscount.toFixed(2),
    // subTotalWithoutDiscount: (getSubtotal + getDiscount).toFixed(2),
    totalWithoutDiscount: (getTotal + getDiscount).toFixed(2),
    voucherDiscount: getVoucherDiscount.voucherDiscount,
    remainingValue: getVoucherDiscount.remainingValue,
    totalAfterAppliedVoucher: getVoucherDiscount.totalAfterAppliedVoucher,
  };

  // const getTax = useMemo(() => {
  //   let calculatedTax = 0;
  //   if (hotel) {
  //     calculatedTax +=
  //       hotel.tax_exclusive === 1
  //         ? total * (hotel.tax / 100)
  //         : (total * 3) / 23;
  //   }
  //   return calculatedTax;
  // }, [hotel, total]);

  // useEffect(() => {
  //   setProducts(state.data);
  // }, [state]);

  // useEffect(() => {
  //   if (hotel && Number(subTotal) > 0) {
  //     let _total =
  //       subTotal +
  //       deliveryCharge +
  //       serviceCharge -
  //       discount +
  //       (hotel?.tax_exclusive === 1 ? tax : 0);
  //     setTotal(_total);
  //   }
  // }, [hotel, subTotal, deliveryCharge, serviceCharge, discount, tax]);

  // useEffect(() => {
  //   let _subTotal = 0,
  //     _discount = 0;
  //   products.forEach((p) => {
  //     let _t = 0;
  //     const size =
  //       p.sizes.length > 0 && p.size
  //         ? p.sizes.find((elem) => elem.id === Number(p.size))
  //         : null;
  //     if (p.sale_price) {
  //       _t += size ? Number(size.size_amount) : Number(p.sale_price);
  //     }
  //     if (p.count) {
  //       _t = _t * Number(p.count);
  //     }
  //     if (p.modifiers?.length) {
  //       p.modifiers.forEach(
  //         (m) => (_t += Number(m.price) * Number(m.count) * Number(p.count))
  //       );
  //     }
  //     _subTotal += _t;
  //   });

  //   setSubTotal(_subTotal);
  //   setDeliveryCharge(
  //     state.checkoutDetails.orderType === ORDER_TYPES.DELIVERY
  //       ? getDeliveryCharge
  //       : 0
  //   );
  //   setServiceCharge(getSurCharge);
  //   setDiscount(_discount);
  //   setTax(getTax);
  // }, [
  //   products,
  //   previousProducts,
  //   getSurCharge,
  //   getTax,
  //   getDeliveryCharge,
  //   state.checkoutDetails.orderType,
  // ]);

  // return {
  //   subTotal: subTotal.toFixed(2),
  //   deliveryCharge: deliveryCharge.toFixed(2),
  //   serviceCharge: serviceCharge.toFixed(2),
  //   discount: discount.toFixed(2),
  //   tax: tax.toFixed(2),
  //   total: total.toFixed(2),
  // };
};

export default usePriceSummary;
