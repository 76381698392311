import { makeStyles } from "@material-ui/core/styles";
import {
  calculateFontSize,
  setFontColorOnBg,
} from "../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  mainHeaderContainer: {
    zIndex: 500,
    width: "100%",
    height: theme?.shopBanner ? "110px" : "70px",
  },
  scrollMainHeaderContainer: {
    zIndex: 500,
    width: "100%",
    height: "50px",
  },
  headerContainer: {
    zIndex: 500,
    width: "100%",
    height: "85px",
  },
  fixed: {
    position: "fixed",
    top: 0,
  },

  appBar: {
    // height: "inherit",
    // backgroundColor: "black",
    height: "110px",
    // overflow: "hidden",
    position: "static",
    backgroundColor: theme?.palette?.common?.headerColor || "black",
  },
  homeAppBar: {
    // height: "100%",
    // overflow: "hidden",
    // position: "fixed",
    backgroundColor: theme?.palette?.common?.headerColor || "black",
  },
  toolbar: {
    // height: "85px",
    // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
    // padding: theme.spacing(0, 0),
    // [theme.breakpoints.up("sm")]: {
    //   padding: theme.spacing(0, 2),
    // },
    // [theme.breakpoints.up("md")]: {
    //   padding: theme.spacing(0, 12),
    // },
  },
  filterBar: {
    backgroundColor: theme.palette.background.default,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "sticky !important",
    top: "0 !important",
    zIndex: 1100,
    transition: "top 0.3s",
    height: "50px",
    // marginTop: theme?.shopBanner ? "190px" : "0px",
    [theme.breakpoints.up("sm")]: {
      height: "49px",
    },
  },
  fixed: {
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 1100,
  },
  // filterBar: {
  //   position: "sticky",
  //   top: 0,
  //   zIndex: 1100,
  //   backgroundColor: theme?.palette?.common?.headerColor,
  //   boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  //   [theme.breakpoints.up("sm")]: {
  //     height: "49px",
  //   },
  // },
  middleBar: {
    position: "relative",
    height: "240px", // Adjust height as needed
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "white", // Ensure text color is readable on the background
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the overlay color and opacity
    zIndex: 1,
  },
  content: {
    zIndex: 2, // Ensure content is above the overlay
    textAlign: "center",
  },
  logo: {
    width: "150px", // Adjust size as needed
    height: "auto",
    marginBottom: theme.spacing(1),
  },
  location: {
    fontSize: "0.9rem", // Adjust size as needed
    paddingTop: "15px",
    color: "#ffffff",
  },
  title: {
    fontSize: calculateFontSize(20, theme.palette.common.fontVariation),
    fontWeight: 700,
    margin: theme.spacing(0.4),
    color: `${theme.palette.common.headerFontColor} !important`,
  },
  // orderTypeModalContainer: {
  //   width: "350px",
  //   height: "auto",
  // },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common?.background,
    marginLeft: theme.spacing(1),
  },
  mobileSearchContainer: {
    width: "100%",
    display: "flex",
  },
  mobileSearch: {
    width: "100%",
  },
  backIcon: {
    padding: theme.spacing(0),
    // pointerEvents: 'none',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.themeFontColor,
  },
  inputRoot: {
    color: theme.palette.common.themeFontColor,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  btn: {
    // ...theme.typography.rockwell,
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    backgroundColor:
      theme?.palette?.button?.buttonColor || theme.palette.primary.main,
    border: theme?.palette?.button?.buttonBorder
      ? "1px solid black"
      : "1px solid white",
    borderRadius:
      theme?.palette?.button?.borderRadius || theme?.shape?.borderRadius,
    margin: theme.spacing(0, 0, 0, 1),
    color: setFontColorOnBg(
      theme?.palette?.button?.buttonColor || theme.palette.primary.main
    ),
    textTransform: "none",
    textDecoration: " none",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
    },
  },
  iconBtn: {
    color: `${theme.palette.common.headerFontColor} !important`,
  },
  cartIconText: {
    position: "absolute",
    fontSize: calculateFontSize(11, theme.palette.common.fontVariation),
    right: "5px",
    width: "16px",
    backgroundColor: theme.palette.common.red,
    height: "16px",
    borderRadius: "50%",
    top: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  orderTypeModalContainer: {
    width: "400px",
    height: "420px",
    border: theme?.palette?.modal?.modalBorder ? "1px solid black" : null,
    borderRadius: theme?.palette?.modal?.modalBorderRadius || "12px",
  },
  link: {
    textDecoration: "none",
  },
  totalItemsText: {
    borderRadius: "8px",
    background: "#414A50",
    padding: theme.spacing(0.2, 0.8),
    marginLeft: theme.spacing(1.6),
  },
  aboutModalContainer: {
    [theme.breakpoints.up("sm")]: {
      maxHeight: "calc(100% - 100px)",
    },
  },
  drawer: {
    width: 295,
    flexShrink: 0,
    [theme.breakpoints.up("sm")]: {
      width: 350,
    },
    [theme.breakpoints.up("md")]: {
      width: 592,
    },
  },
  drawerPaper: {
    backgroundColor: theme.palette.common.background,
    width: 295,
    [theme.breakpoints.up("sm")]: {
      width: 350,
    },
    [theme.breakpoints.up("md")]: {
      width: 592,
    },
  },
  filterDrawerContainer: {
    padding: theme.spacing(2.4, 1.6, 0, 1.6),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2.4, 4, 0, 4),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2.4, 12, 0, 8),
    },
  },
  filterDrawerTitle: {
    fontWeight: 700,
    fontSize: calculateFontSize(16, theme.palette.common.fontVariation),
    marginBottom: theme.spacing(2.4),
  },
  headerLogo: {
    height: "100px",
  },
}));

export default useStyles;
