export const APP_NAME = process.env.REACT_APP_NAME;

export const TAKEY_APP_NAME = "Takey";
export const SIPO_APP_NAME = "Sipo";
export const SILVERBACK_APP_NAME = "Silverback";
export const STAGING_SIPO_APP_NAME = "Staging_sipo";

export const FONTS = {
  REGULAR: "Poppins-Regular",
  BOLD: "Poppins-Bold",
};

export const APP_CONFIG = {
  SERVER_SECRET: "#FH625SAZcCdcG",
  CURRENCY: "nzd",
};
export const API = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  BASE_URL_NODE: process.env.REACT_APP_BASE_URL_NODE,
  BASE_URL_SOCKET: process.env.REACT_APP_BASE_URL_SOCKET,
  BASE_UTL_SOCKET_V2: process.env.REACT_APP_BASE_URL_SOCKET_v2,

  // BASE_URL: "https://sipocloudpos.com/sipo_server_live/public/api",
  VERSION: "",
  ENDPOINTS: {
    GET_TABLE_DETAILS: "tables/gettableDetails",
    TABLE_OPTION_REQUEST: "push",
    GET_RESTAURANT: "getRestaurant",
    GET_WALLET_HISTORY: "cust_wallet_points_transactions",
    GET_COMBOS: "getCombos",
    GET_MENU: "get_menu",
    GET_ADDONS: "getAddOns",
    TRIGGER_EMAIL_VERIFICATION: "trigger_email_verification",
    VERIFY_EMAIL_OTP: "verify_customer_email",
    ONLINE_ORDER: "checkout",
    VERIFY_PAYMENT: "payment_update",
    ORDER_STATUS: "online_order_status",
    ORDER_HISTORY: "customer/getOrders",
    TAKEY_GET_NEAREST_RESTAURANT: "nearest_restaurants",
    TAKEY_CONTACT_US: "add_contact_form",
    TAKEY_JOIN_US: "add_join_form",
    PROD_SUGGEST: "product_suggestion_list_online",
    TAKEY_DRIVE_WITH_US: "add_driver_form",
    GET_COUPON: "coupon_get",
    VALIDATE_COUPON: "coupon_check",
    COUPON_VALIDATE: "validate_coupon",
    CREATE_USER: "customer/create",
    LOGIN_USER: "customer/getdata",
    ONLINE_TABLES: "online_tables",
    VOUCHER_CHECK: "vouchers/get/code_passs",
    CUSTOMER_ORDERS: "get_customer_orders",
    CUSTOMER_WALLET_POINTS: "get_customer_wallet_points",
    CART_SUGGESTION: "cart_suggestion_list_online",
    ORDER_DETAILS: "order_details_fetch_by_order_id",
    ACCOUNT_DELETE: "customer_delete",
    EMAIL_LOGIN_OTP_REQUEST: "send-otp",
    EMAIL_OTP_VERIFY: "verify-otp",
  },

  // https://sipocloudpos.com/dev_server/public/api/checkout
};

export const PXPAY_CONFIG = {
  // DEV
  BASE_URL: "https://sec.windcave.com/pxaccess/pxpay.aspx",
  PxPayUserId: "DDINFOWAYS_Dev",
  PxPayKey: "3ad662aeefb0d524817e417919fee91e5a79df45790aef29f4221c72a3db1c8c",
};

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

// export const BUGSNAG_KEY = "3d517a0768a822879c6e36a56f748578";//test
export const BUGSNAG_KEY = "9315c52ec380f925e75ff805a2a8e1d1";

export const APP_VERSION = process.env.REACT_APP_VERSION;
export const ORDER_TYPES = {
  OPENING: "OPENING",
  DELIVERY: "DELIVERY",
  PICKUP: "PICKUP",
  DINEIN: "DINEIN",
  TABLE_BOOKING_AND_ORDERING: "TABLE_BOOKING_AND_ORDERING",
};
export const DELIVERY_PICKUP_TYPES = {
  ASAP: "ASAP",
  SCHEDULED: "SCHEDULED",
};
export const PAYMENT_METHODS = {
  ONLINE_PAYMENT: "ONLINE_ORDER_CARD",
  PAY_AT_COUNTER: "PAY_AT_COUNTER",
};

export const ORDER_STATUS = {
  ACCEPTED: "ACCEPTED",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
};
export const PAYMENT_STATUS = {
  SUCCEEDED: "succeeded",
  FAILED: "failed",
  INPROGRESS: "in_progress",
};

export const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const MESSAGES = {
  INVALID_LOCATION:
    "Delivery service is not available for the selected location",
  INVALID_TOTAL_AMOUNT: "Delivery service is not available for order below",
  RESTURANT_OPENING_CLOSED: {
    TITLE: "We’re currently closed",
    MESSAGE: "You can always pre order",
  },
  RESTURANT_DELIVERY_CLOSED: {
    TITLE: "We’re currently closed for delivery",
    MESSAGE: "",
  },
  RESTURANT_PICKUP_CLOSED: {
    TITLE: "We’re currently closed for pickup",
    MESSAGE: "",
  },
  RESTURANT_PREORDER_AND_OPENING_CLOSED: {
    TITLE: "Warning",
    MESSAGE:
      "You have visited outside of normal opening hours , you can pre order for later",
  },
};

export const ERROR_TYPES = {
  DEFAULT: "DEFAULT",
  RESTURANT: "RESTURANT",
  INVALID_LOCATION: "LOCATION",
  MIN_AMOUNT_ORDER: "MIN_AMOUNT_ORDER",
};

export const TAKEY_SIDEMENU_OPTIONS = {
  JOIN_US: "Join us",
  DRIVE_WITH_US: "Drive with us",
  CONTACT_US: "Contact us",
};

export const ALL_DELIVERY_TYPES = "ALL";

export const COUPONS = {
  ITEM_DISCOUNT: "ITEM_DISCOUNT",
  CART_DISCOUNT: "CART_DISCOUNT",
  FREE_DELIVERY: "FREE_DELIVERY",
  BUY_ONE_GET_ONE_FREE: "BUY_ONE_GET_ONE_FREE",
};

export const SORTS = {
  DEFAULT: "Default",
  AZ: "A-Z",
  ZA: "Z-A",
  HIGH_LOW_PRICE: "High_Low_Price",
  LOW_HIGH_PRICE: "Low_High_Price",
};

export const SORT_OPTIONS = [
  { label: "Default", value: SORTS.DEFAULT },
  { label: "A-Z", value: SORTS.AZ },
  { label: "Z-A", value: SORTS.ZA },
];

export const SORT_OPTIONS_SUB = [
  { label: "Default", value: SORTS.DEFAULT },
  { label: "A-Z", value: SORTS.AZ },
  { label: "Z-A", value: SORTS.ZA },
  { label: "Low-High Price", value: SORTS.LOW_HIGH_PRICE },
  { label: "High-Low Price", value: SORTS.HIGH_LOW_PRICE },
];

export const CURRENCY = {
  SIGN: process.env.REACT_APP_CURRENCY_SIGN,
  NAME: process.env.REACT_APP_CURRENCY_NAME,
};

export const ADDRESS_TYPES = ["HOME", "WORK", "OTHER"];
export const ADDRESS_TYPE_HOME = "HOME";
export const ADDRESS_TYPE_WORK = "WORK";
export const ADDRESS_TYPE_OTHER = "OTHER";

export const ONLINE_ORDER_WEB = "ONLINE_ORDER_WEB";

export const GET_USER_IP_API = "https://api.ipify.org?format=json";
