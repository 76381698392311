// Icons
import CloseIcon from "@material-ui/icons/Close";
// Material
import Typography from "@material-ui/core/Typography";

import IconBtn from "../../Controls/IconButton/IconButton";
import useStyles from "./CouponList.style";
import { useDispatch, useSelector } from "react-redux";
import {
  // getCoupon,
  selectCoupon,
  setCouponValidationResult,
  setIsFreeDeliveryCouponApplied,
  updateCart,
  validateCoupon,
} from "../../../store/Cart/CartActions";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TextField, Button } from "@material-ui/core";
import { resetWallet } from "../../../store/Profile/ProfileActions";
import useWalletPoints from "../../../CustomHooks/useWalletPoints";
import SpecialAlert from "../../SpecialAlert/SpecialAlert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SkipNext } from "@material-ui/icons";
import store from "../../../store";
import CouponSuccess from "../CouponSuccess/CouponSuccess";
import ModalComponent from "../../Material/Modal/Modal";
import { set } from "lodash";
import EmailInputModal from "./EmailInputModal";
import { setLocalStorageItem } from "../../../utils/utils";
import { COUPONS } from "../../../utils/Constants";

const CouponList = ({ handleClose = () => {}, coupons, handleSkip }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [manualCode, setManualCode] = useState("");
  const { hotel, hotelKey } = useSelector((state) => state.hotel);
  const {
    selectedCoupon,
    coupons: allCoupons,
    data,
    isValidating,
    coupon_validation_result,
    validatedCoupons,
  } = useSelector((state) => state.cart);

  const { menuVersion, sortedCategoryMenu } = useSelector(
    (state) => state.menu
  );
  const { resetWalletProducts } = useWalletPoints();
  const [itemModal, setItemModal] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [isFreedevliveryCoupon, setIsFreeDeliveryCoupon] = useState(false);
  const user = useSelector((state) => state.user);
  const [openEmailInput, setOpenEmailInput] = useState(false);
  const [bogfType, setBogfType] = useState(false);
  const [selectedCoup, setSelectedCoup] = useState(null);
  const [emailInput, setEmailInput] = useState("");
  const getUniqueCoupons = useMemo(() => {
    return allCoupons.filter(
      ({ id: id1 }) => !coupons.some(({ id: id2 }) => id2 === id1)
    );
  }, [allCoupons, coupons]);

  const isHaveFreeProduct = (c) => {
    // console.log("cccccccccccc", c);
    return c?.result?.DISCOUNT === 100;
  };
  // console.log("hotel", hotel);
  // const couponClickHandler = (c) => {
  //   dispatch(resetWallet());
  //   resetWalletProducts(2);
  //   // console.log("ccccc", c);
  //   // const payload = {
  //   //   customerId: user?.email,
  //   //   code: c?.code,
  //   // };
  //   // dispatch(validateCoupon(payload));
  //   if (c?.result?.type === "ITEM_DISCOUNT") {
  //     if (isHaveFreeProduct(c)) {
  //       setSelectedProducts(c);
  //       setItemModal(true);
  //     } else {
  //       dispatch(selectCoupon(c));
  //     }
  //   } else {
  //     dispatch(selectCoupon(c));
  //   }
  //   // handleClose(true);
  // };
  const couponClickHandler = async (c) => {
    setSelectedCoup(c);
    if (isValidating) return;
    dispatch(resetWallet());
    resetWalletProducts(2);
    if (!user?.email && !emailInput) {
      setOpenEmailInput(true);
      return; // ⬅️ Prevent further execution
    }

    const payload = {
      customerId: user?.email || emailInput,
      code: c?.code,
      store_id: hotel?.hotelId || null,
      result_status: false,
      isNewCoupon: false,
    };
    // Call validateCoupon and wait for the result
    // console.log("validateCoupon");
    await dispatch(validateCoupon(payload));
    // console.log("response in funccccccc", response);
    // await new Promise((resolve) => setTimeout(resolve, 100));
    // if (coupon_validation_result) {
    //   // Proceed with the valid coupon logic
    //   if (c?.result?.type === "ITEM_DISCOUNT") {
    //     if (isHaveFreeProduct(c)) {
    //       setSelectedProducts(c);
    //       setItemModal(true);
    //       return;
    //     } else {
    //       dispatch(selectCoupon(c));
    //     }
    //   } else {
    //     dispatch(selectCoupon(c));
    //   }
    //   handleClose(true);
    // } else {
    //   console.log("Coupon is not valid.");
    // }
    // Now, check the state
    // const coupon_validation_result =
    //   store.getState().cart.coupon_validation_result;
  };
  // const availCouponClickHandler = (code) => {
  //   const payload = {
  //     hotelId: hotel.hotelId,
  //     code: code,
  //   };
  //   dispatch(validateCoupon(payload));
  // };

  useEffect(() => {
    if (coupon_validation_result) {
      // Proceed with the valid coupon logic
      // console.log("selected couponnnnn", selectedCoupon);
      // console.log("validatedCoupons", validatedCoupons);
      if (validatedCoupons?.result?.type === "ITEM_DISCOUNT") {
        if (isHaveFreeProduct(validatedCoupons)) {
          setSelectedProducts(validatedCoupons);
          dispatch(setIsFreeDeliveryCouponApplied(false));
          setItemModal(true);
          return;
        } else {
          // console.log("working 222222222222");
          setSelectedProducts(validatedCoupons);
          // setItemModal(true);
          dispatch(selectCoupon(validatedCoupons));
          handleClose(true);
          return;
        }
      } else if (validatedCoupons?.result?.type === "FREE_DELIVERY") {
        setIsFreeDeliveryCoupon(true);
        setItemModal(true);
        dispatch(setIsFreeDeliveryCouponApplied(true));
        setSelectedProducts(validatedCoupons);
        // dispatch(selectCoupon(validatedCoupons));
        return;
      } else if (validatedCoupons?.result?.type === "BUY_ONE_GET_ONE_FREE") {
        setBogfType(true);
        setSelectedProducts(validatedCoupons);
        setItemModal(true);
        return;
      } else {
        dispatch(selectCoupon(validatedCoupons));
      }
      handleClose(true);
    } else {
      console.log("Coupon is not valid.");
    }
  }, [coupon_validation_result, validatedCoupons]);

  const applyCoupon = () => {
    // const payload = {
    //   hotelId: hotel.hotelId,
    //   code: manualCode,
    // };
    const payload = {
      customerId: user?.email || null,
      code: manualCode,
      store_id: hotel?.hotelId || null,
      result_status: false,
      isNewCoupon: true,
    };
    dispatch(validateCoupon(payload));
    // setManualCode("");
  };

  // useEffect(() => {
  //   if (selectedCoupon?.id) {
  //     handleClose(true);
  //   }
  // }, [selectedCoupon]);

  // const alertTitle = useMemo(() => {
  //   console.log("validatedCoupons", validatedCoupons);
  //   const items = validatedCoupons.products || [];
  //   if (items.length > 1) {
  //     return `You can get ${items
  //       .slice(0, -1)
  //       .map((product) => product.name)
  //       .join(", ")} and ${items.slice(-1)[0].name} free`;
  //   } else if (items.length === 1) {
  //     return `You get ${items[0].name} free`;
  //   } else {
  //     return `You get a free product`;
  //   }
  // }, [validatedCoupons]);

  const alertTitle = useMemo(() => {
    if (!isFreedevliveryCoupon) {
      if (validatedCoupons) {
        if (validatedCoupons?.result?.type === "BUY_ONE_GET_ONE_FREE") {
          // ✅ BOGOF LOGIC: Extract applicable item IDs from APPLICABLE_ITEMS
          const selectedItems =
            validatedCoupons?.rules?.APPLICABLE_ITEMS?.map((rule) => rule.id) ||
            [];

          const freeProducts = data
            ?.filter((item) => selectedItems.includes(item.itemId)) // Check if itemId exists in APPLICABLE_ITEMS
            ?.reduce((acc, item) => {
              if (!acc.includes(item.label)) {
                acc.push(item.label); // Add only unique labels
              }
              return acc;
            }, [])
            .join(", "); // Join unique labels with commas

          if (freeProducts) {
            return `You get ${freeProducts} for free!`;
          }
        }
        return `You get ${validatedCoupons?.name} for free`;
      } else {
        return `You get a free product`;
      }
    } else {
      return `You have successfully applied a delivery fee coupon and will save ${validatedCoupons?.result?.MAX_DISCOUNT_VALUE} dollars on your delivery!`;
    }
  }, [data, validatedCoupons, isFreedevliveryCoupon]);

  const calculateDiscountPercentage = (
    discountPercentage,
    maxDiscountValue,
    item
  ) => {
    if (item?.sale_price > maxDiscountValue) {
      return (maxDiscountValue / item?.sale_price) * 100;
    } else {
      return discountPercentage;
    }
  };

  const getSizePrice = (size, options, base) => {
    // console.log("optinossssss", options);
    const elem = options?.find((elem) => elem.id === Number(size));
    // console.log("elemmmmm", elem);
    return elem ? elem.size_amount : base;
  };

  const calculateBOGFPercentage = (item) => {
    let discountPercent = 0;

    if (!selectedCoupon?.result?.type) {
      return 0;
    }

    const applicableItems = JSON.parse(selectedCoupon?.items) || [];

    if (!applicableItems.length) {
      return 0;
    }

    // console.log("working 1111111111111111", selectedCoupon);

    // 1. Handle BUY ONE GET ONE FREE discount
    if (selectedCoupon?.result?.type === COUPONS.BUY_ONE_GET_ONE_FREE) {
      if (applicableItems.includes(item?.itemId)) {
        const itemSalePrice = item?.size
          ? getSizePrice(item.size, item.sizes, item.sale_price)
          : item.sale_price;

        const itemCount = item?.count || 0; // Number of items purchased
        const freeCount = item?.freeCount || 0; // Free items count

        const totalPaid = itemCount * itemSalePrice;
        const totalDiscount = freeCount * itemSalePrice;

        // Avoid division by zero
        const discountPercentage =
          totalPaid > 0 ? (totalDiscount / totalPaid) * 100 : 0;

        return discountPercentage;
      }
      return 0;
    }

    return discountPercent;
  };

  // Function to handle the close of the item modal and add free product
  const handleCloseItem = useCallback(() => {
    if (isAddingProduct) return;
    setIsAddingProduct(true);

    if (bogfType) {
      // ✅ BOGOF LOGIC
      const applicableItems = selectedProducts?.rules?.APPLICABLE_ITEMS || []; // Keep full objects to access sizes
      const applicableItemIds = applicableItems.map((rule) => rule.id); // Extract IDs
      const maxFreeCount = selectedProducts?.result?.max_free_count || 0;

      // Step 1: Aggregate total count of each product in the cart
      const itemCounts = data?.reduce((acc, cartItem) => {
        if (applicableItemIds.includes(cartItem.itemId)) {
          const key = cartItem.size
            ? `${cartItem.itemId}-${cartItem.size}`
            : `${cartItem.itemId}-nosize`; // Unique key for size or no-size case
          acc[key] = (acc[key] || 0) + cartItem.count;
        }
        return acc;
      }, {}); // Object with { "itemId-size": total count in cart }

      // console.log("itemCounts", itemCounts);
      let totalFreeAdded = 0; // Track total free items added

      // Step 2: Loop through unique applicable items and add free products based on total count
      Object.entries(itemCounts).forEach(([key, totalCount]) => {
        if (totalFreeAdded >= maxFreeCount) return; // Stop adding free items if max limit is reached
        // console.log("keyyyyyyy", key);
        const [itemId, size] = key.split("-"); // Extract itemId and size
        const isSizeAvailable = size !== "nosize";

        const cartItem = data.find(
          (cartItem) =>
            cartItem.itemId === Number(itemId) &&
            (isSizeAvailable
              ? String(cartItem.size) === String(size)
              : !cartItem.size)
        );

        // Check if the size exists in APPLICABLE_ITEMS
        const applicableItem = applicableItems.find(
          (item) => item.id === Number(itemId)
        );
        const applicableSizes = applicableItem?.sizes.map(String) || []; // Convert applicable sizes to string for comparison

        let selectedSize = null;
        if (isSizeAvailable && applicableSizes.includes(String(size))) {
          selectedSize = String(size); // Keep the same size if it's in APPLICABLE_ITEMS
        } else if (applicableSizes.length > 0) {
          selectedSize = applicableSizes[0]; // Otherwise, use the first applicable size
        }

        let freeCount = Math.min(totalCount, maxFreeCount - totalFreeAdded); // Ensure we don't exceed maxFreeCount
        totalFreeAdded += freeCount; // Update total free count added

        sortedCategoryMenu?.forEach((category) => {
          category?.item?.forEach((it) => {
            if (it.itemId === Number(itemId)) {
              let salePrice = isSizeAvailable
                ? null
                : cartItem?.salePrice || it.salePrice; // Use salePrice only when no size

              dispatch(
                updateCart({
                  ...it,
                  count: freeCount, // Add free items based on total count in cart
                  modifiers: [],
                  size: selectedSize, // Use selected size logic
                  salePrice: salePrice, // Set sale price only when no size
                  freeCount: freeCount,
                  isAutoAdded: true,
                  itemDiscountEnabled: true,
                  itemDiscount: calculateBOGFPercentage(it),
                  isEditMode: false,
                  menuVersion,
                  categoryId: category.categoryId,
                })
              );
            }
          });
        });
      });
    } else {
      // ✅ REGULAR COUPON LOGIC
      if (selectedProducts?.items?.length > 0) {
        sortedCategoryMenu?.forEach((category) => {
          category?.item?.forEach((it) => {
            const selectedItemArray = JSON.parse(selectedProducts?.items);
            const isItemIdIncluded = selectedItemArray.includes(it?.itemId);

            if (isItemIdIncluded) {
              dispatch(
                updateCart({
                  ...it,
                  count: 1,
                  modifiers: [],
                  size: [],
                  freeCount: 0,
                  itemDiscountEnabled: true,
                  itemDiscount:
                    calculateDiscountPercentage(
                      selectedProducts?.result?.DISCOUNT,
                      selectedProducts?.result?.MAX_DISCOUNT_VALUE,
                      it
                    ) || 0,
                  isEditMode: false,
                  menuVersion,
                  isAutoAdded: true,
                  categoryId: category.categoryId,
                })
              );
            }
          });
        });
      }
    }

    // ✅ Common actions for both BOGOF and regular coupons
    dispatch(selectCoupon(selectedProducts));
    setItemModal(false);
    handleClose(false);
    dispatch(setCouponValidationResult(false));

    setTimeout(() => setIsAddingProduct(false), 500);
  }, [
    isAddingProduct,
    bogfType,
    selectedProducts,
    sortedCategoryMenu,
    data,
    dispatch,
    handleClose,
  ]);

  const handleEmailInputClose = () => {
    setOpenEmailInput(false);
  };

  const handleEmailInputSave = (email) => {
    setEmailInput(email);
    setLocalStorageItem("emailVerified", email);
    setOpenEmailInput(false);
    couponClickHandler(selectedCoup);
  };

  return (
    <div className={classes.root}>
      {/* <div className={classes.skipBtnDiv}> */}
      {/* <Button className={classes.skipBtn} onClick={handleSkip}>
          Skip without Coupon
        </Button> */}
      <IconBtn
        aria-label="close"
        component="span"
        icon={<SkipNext />}
        onClick={handleSkip}
        text={"Skip"}
        className={classes.skipBtn}
      />
      <IconBtn
        aria-label="close"
        component="span"
        icon={<CloseIcon />}
        text={"Close"}
        onClick={() => handleClose(false)}
        className={classes.closeButton}
      />
      {/* </div> */}
      <div className={classes.scrollContainer}>
        <div className={classes.detailsContianer}>
          <div className={classes.infoContainer}>
            {/* <Typography className={classes.title} variant="h3">
              Available Coupons
            </Typography> */}
            <div className={classes.entercoupon}>
              <TextField
                className={classes.couponInput}
                label="Enter Coupon Code"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                  shrink: true,
                }}
                onChange={(e) => setManualCode(e.target.value)}
              />
              <Button
                className={classes.couponBtn}
                variant="contained"
                color="primary"
                onClick={applyCoupon}
                disabled={!manualCode}
              >
                APPLY
              </Button>
            </div>
            <Typography className={classes.title} variant="h3">
              Available Coupons
            </Typography>
            {coupons && coupons.length !== 0 ? (
              <>
                {coupons?.map((c) => (
                  <div
                    className={classes.listCont}
                    onClick={() => couponClickHandler(c)}
                    key={c.id}
                  >
                    <div className={classes.couponLeftDiv}>
                      <Typography className={classes.subTitle} variant="h3">
                        {c.name || ""}
                      </Typography>
                      <Typography className={classes.description} variant="h3">
                        {c?.description || ""}
                      </Typography>
                    </div>
                    <Typography className={classes.redeemText} variant="body1">
                      Click to Redeem
                    </Typography>
                    <Button
                      className={classes.couponBtn}
                      variant="contained"
                      // color="primary"
                      // onClick={() => couponClickHandler(c)}
                      disabled={manualCode}
                    >
                      APPLY
                    </Button>
                  </div>
                ))}
              </>
            ) : (
              <Typography
                className={`text-center pt-2 ${classes.noCouponText}`}
                variant="h3"
              >
                No coupons available
              </Typography>
            )}
            {getUniqueCoupons.length !== 0 && (
              <>
                <Typography
                  className={`py-2 ${classes.otherCouponText}`}
                  variant="h3"
                >
                  Other Coupons
                </Typography>
                {getUniqueCoupons?.map((c) => (
                  <div
                    className={classes.listContDis}
                    // onClick={(c) => availCouponClickHandler(c?.id)}
                    key={c.id}
                  >
                    <Typography className={classes.subTitle} variant="h3">
                      {c.name || ""}
                    </Typography>
                    <Typography className={classes.description} variant="h3">
                      {c?.description || ""}
                    </Typography>
                  </div>
                ))}
              </>
            )}
          </div>
          {/* <div className={classes.skipBtnDiv}>
            <Button className={classes.skipBtn} onClick={handleSkip}>
              Skip without Coupon
            </Button>
          </div> */}
        </div>
      </div>
      {/* <SpecialAlert
        open={itemModal}
        title={alertTitle}
        handleClose={handleCloseItem}
      /> */}
      <ModalComponent
        customView={true}
        open={itemModal}
        handleClose={handleCloseItem}
        modelContainer={classes.orderTypeModalContainer}
      >
        {itemModal && (
          <CouponSuccess
            isCustomTitle={true}
            discount={alertTitle}
            // directCheckOut={false}
            handleClose={handleCloseItem}
          />
        )}
      </ModalComponent>
      <EmailInputModal
        open={openEmailInput}
        handleClose={handleEmailInputClose}
        handleSave={handleEmailInputSave}
      />
    </div>
  );
};

export default CouponList;
