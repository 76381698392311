import { Box, Button, Modal, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import useStyles from "./EmailInputModal.style";

const EmailInputModal = ({ open, handleClose, handleSave }) => {
  const [email, setEmail] = useState("");
  const classes = useStyles();

  const handleSubmit = () => {
    if (email.trim() !== "") {
      handleSave(email);
      //   handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="email-modal-title"
    >
      <Box className={classes.root}>
        <Typography id="email-modal-title" variant="h6" mb={2}>
          Enter Your Email
        </Typography>
        <TextField
          fullWidth
          label="Email Address"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button onClick={handleClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.saveBtn}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EmailInputModal;
